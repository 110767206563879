<template>
    <div class="location" :class="{m_location:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[4].menuList[3]').toUpperCase() }}</span>
            </transition>
        </TitleH>

        <div class="location_main">
            <div class="main-nav">
                {{ $t('Media.connection').toUpperCase() }}
            </div>
            <div class="main-connection">
                <div class="connection_item" v-for="(value,index) in contact" :key="index">
                    <div class="item-img">
                        <img :src="imgUrl(baseUrl,value.iconUrl)" alt="">
                    </div>
                    <div class="item-content">
                        <span class="title">{{ value.title }}</span>
                        <span class="emit">{{ value.mail }}</span>
                    </div>
                </div>
            </div>
            <div class="main-nav">
                {{ $t('Media.address').toUpperCase() }}
            </div>
            <div class="main-title">
                <span v-for="(value,index) in countrys"
                      :key="index"
                      @click="regionChange(index)"
                      :class="{active:type===index}">
                            {{ value.name }}
                </span>
            </div>
            <div class="main-content">
                <div class="list" v-for="(value,index) in countrys" :key="index"
                     :style="{'display':type===index?'flex':'none'}">
                    <div class="list_item" v-for="(val,i) in value.locations" :key="i">
                        <div>{{ val.cityName }}</div>
                        <p>{{ val.address }}</p>
                        <p>{{ val.zipCode }}</p>
                        <p>{{ val.tell }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!--移动端-->
        <div class="location_small">
            <div class="location_small_item" v-for="(value,index) in countrys" :key="index">
                <div class="item-title">{{ value.name }}
                    <div class="arrows" @click="smallTypeChange(index)" :class="{active:smallType===index}"></div>
                </div>
                <div class="item-location" :class="{active:smallType===index}">
                    <div class="item-location_item" v-for="(val,i) in value.locations" :key="i"
                         :class="{await:smallType===index}">
                        <div>{{ val.cityName }}</div>
                        <p>{{ val.address }}</p>
                        <p>{{ val.zipCode }}</p>
                        <p>{{ val.tell }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "LocationModule",
    components: {
        TitleH
    },
    props: ['countrys', 'contact'],
    data () {
        return {
            type: 0,
            //对应的要展开的数值
            smallType: 0,
            flag: false
        }
    },
    methods: {
        imgUrl,
        regionChange (id) {
            this.type = id
        },
        smallTypeChange (index) {
            //console.log(index)
            this.smallType = index
            this.flag = true
            console.log(this.flag)
        }
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------LocationModule---------- --*/
.location {
    padding: 5rem 0;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;

    &_main {
        padding: 6.25rem 7.8125rem;

        .main-nav {
            padding-bottom: 4.0625rem;
            font-size: 2.25rem;
            line-height: 3rem;
            font-family: 'AlibabaPuHuiTiM';
        }

        .main-connection {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.25rem;

            .connection_item {
                display: flex;
                width: 25%;
                margin-bottom: 4.375rem;
                padding-left: 3.125rem;
                box-sizing: border-box;

                .item-img {
                    margin-right: 0.5rem;
                    width: 2rem;

                    img {
                        width: 100%;
                    }
                }

                .item-content {

                    .title {
                        font-family: 'AlibabaPuHuiTiM';
                        font-size: 1.5rem;
                        display: block;
                        //line-height: 3rem;
                        white-space: nowrap;
                    }

                    .emit {
                        font-family: 'AlibabaPuHuiTiL';
                        font-size: 1.125rem;
                        display: block;
                    }
                }
            }
        }

        .main-title {
            color: #1F1F1F;
            font-size: 2.25rem;
            //font-weight: bold;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #C7C4C7;

            span {
                cursor: pointer;
                padding: 1.125rem 2.75rem;
                font-family: 'AlibabaPuHuiTiM';
            }

            .active {
                border-bottom: 0.25rem solid #ED121B;
            }
        }

        .main-content {
            position: relative;
            width: 100%;
            margin: 3.125rem 0;
            height: auto;
            min-height: 12.5rem;

            .list {
                width: 100%;
                //position: absolute;
                //top: 0;
                //left: 0;
                //display: flex;
                display: none;
                justify-content: left;
                flex-wrap: wrap;
                //opacity: 0;
                height: auto;

                &_item {
                    width: 48%;
                    background-color: #F7F9FB;
                    padding: 2.5rem 2.1875rem;
                    box-sizing: border-box;
                    margin-bottom: 1.25rem;
                    margin-right: 4%;

                    div {
                        font-size: 1.5rem;
                        margin-bottom: 1.5rem;
                    }

                    p {
                        font-size: 1.5rem;
                        font-family: 'AlibabaPuHuiTiL';

                    }

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

            }


        }
    }

    &_small {
        padding: 3.125rem 0;
        display: none;

        &_item {

            .item-title {
                width: 100%;
                height: 5.375rem;
                font-size: 1.875rem;
                line-height: 5.3725rem;
                position: relative;
                font-weight: bold;
                border-bottom: 0.125rem solid #C7C4C7;

                .arrows {
                    width: 1.5625rem;
                    height: 0.8125rem;
                    background-image: url('../../../assets/m_02.png');
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    position: absolute;
                    right: 2.625rem;
                    top: calc(50% - 0.4375rem);
                    transform: rotateZ(180deg);
                }

                .active {
                    transform: rotateZ(0);
                    transition: all 0.5s;
                }
            }

            .item-location {
                padding: 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                text-align: justify;
                &_item {
                    width: 48%;
                    background-color: #F7F9FB;
                    padding: 2.5rem 2.1875rem;
                    box-sizing: border-box;
                    display: none;
                    margin-bottom: 1.25rem;
                    text-align: justify;
                    div {
                        font-size: 1.5rem;
                        margin-bottom: 1.5rem;
                    }

                    p {
                        font-size: 1.5rem;
                        font-family: 'AlibabaPuHuiTiL';
                        text-align: justify;

                    }
                }

                .await {
                    display: block;
                }
            }

            .active {
                padding: 1.6875rem 0;
            }
        }

        .ip {
            font-weight: 800;
            display: inline-block;
            position: relative;
            font-size: 1.875rem;;
        }
    }

}

@import "css/m-location";
</style>
