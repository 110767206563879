<template>
    <div class="dynamic" :class="{m_dynamic:$store.state.screenType}" >
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[4].menuList[0]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <DynamicSwiper v-show="!$store.state.screenType " :news="company" v-if="company.length"></DynamicSwiper>
        <div class="bot" v-if="company.length">
            <div class="all" @click="jumpPage">
                <span>{{ $t('Media.all') }}</span>
                <div class="next"></div>
            </div>
        </div>
        <div class="dynamic_small" >
            <div class="dynamic_small_show" v-for="val in 2" v-if="company.length" @click="jumpContent(company[0][val-1].newsId)">
                <div class="cover">
                    <img :src="imgUrl(baseUrl,company[0][val-1].coverUrl)" alt="">
                </div>
                <div class="show-content">
                    <p class="time">{{ $getTime(company[0][val-1].createTime).year }}/{{ $getTime(company[0][val-1].createTime).month }}/{{ $getTime(company[0][val-1].createTime).day }}</p>
                    <span class="title">{{ company[0][val-1].newsTitle }}</span>
                    <p class="content">{{ company[0][val-1].newsDes }}</p>
                </div>
                <div class="show-next"></div>
            </div>
            <div class="dynamic_small_all" @click="jumpPage">
                {{ $t('Media.all') }}
                <div class="next"></div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import DynamicSwiper from './DynamicModule/DynamicSwiper'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'
import getTime from '../../../tools/getTime'

export default {
    name: "DynamicModule",
    components: {
        TitleH,
        DynamicSwiper
    },
    props:['company'],
    data () {
        return {
        }
    },
    methods: {
        imgUrl,
        jumpPage () {
            console.log('s')
            this.$router.push({
                path: '/movement',
                query: {
                    type: '企业动态'
                },
                
            })
        },
        jumpContent(id){
            this.$router.push({
                path: '/content',
                query:{
                    id:id
                },
                
            })
        },

    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------DynamicModule---------- --*/
.dynamic {
    font-size: 0;
    padding: 6rem 7.5rem;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;

    &_small {
        display: none;
    }

    .bot {
        width: 100%;
        position: relative;
        height: 2.5rem;
        padding: 2.5rem 0;
        font-family: 'AlibabaPuHuiTiM';

        span {
            margin-right: 1.875rem;
        }

        .all {
            width: auto;
            height: 2.5rem;
            border-radius: 0.5rem;
            background-color: #ed121b;
            color: #fff;
            line-height: 2.5rem;
            position: absolute;
            top: -1.5rem;
            right: 1.5625rem;
            padding: 0 1rem;
            text-align: left;
            font-size: 1rem;
            z-index: 100;
            cursor: pointer;
            .next {
                width: 1.375rem;
                height: 0.875rem;
                background-image: url('../../../assets/banner_next.png');
                background-size: 100% auto;
                position: absolute;
                right: 1rem;
                bottom: 0.725rem;
            }
        }
    }
}

@import "m-dynamic";
</style>
