<template>
    <div class="Swiper">
        <div class="swiper-container game" ref="swiper" >
            <!--@mouseenter="mouseEnter" @mouseleave="mouseLeave"-->
            <div class="swiper-wrapper">
                <div class="dynamic_content swiper-slide" v-for="(value,index) in news" :key="index"
                >
                    <!---->
                    <div class="dynamic_content_show" v-if="value[0]">
                        <div class="cover">
                            <img :src="imgUrl(baseUrl,value[0].coverUrl)" alt="">
                        </div>
                        <div class="show-content">
                            <p class="time">{{ $getTime(value[0].createTime).year }}/{{
                                    $getTime(value[0].createTime).month
                                }}/{{ $getTime(value[0].createTime).day }}</p>
                            <span class="title">{{ value[0].newsTitle }}</span>
                            <p class="content">{{ value[0].newsDes }}</p>
                        </div>
                        <div class="significance">
                            <p class="time">{{ $getTime(value[0].createTime).year }}/{{
                                    $getTime(value[0].createTime).month
                                }}/{{ $getTime(value[0].createTime).day }}</p>
                            <span class="title">{{ value[0].newsTitle }}</span>
                            <p class="content">{{ value[0].newsDes }}</p>
                            <div class="all" @click="jump(value[0].newsId)">
                                {{ $t('Media.more') }}
                                <div class="next"></div>
                            </div>
                        </div>
                        <div class="next"></div>
                    </div>
                    <!---->
                    <div class="dynamic_content_show" v-if="value[1]">
                        <div class="cover">
                            <img :src="imgUrl(baseUrl,value[1].coverUrl)" alt="">
                        </div>
                        <div class="show-content">
                            <p class="time">{{ $getTime(value[1].createTime).year }}/{{
                                    $getTime(value[1].createTime).month
                                }}/{{ $getTime(value[1].createTime).day }}</p>
                            <span class="title">{{ value[1].newsTitle }}</span>
                            <p class="content">{{ value[1].newsDes }}</p>
                        </div>
                        <div class="significance">
                            <p class="time">{{ $getTime(value[1].createTime).year }}/{{
                                    $getTime(value[1].createTime).month
                                }}/{{ $getTime(value[1].createTime).day }}</p>
                            <span class="title">{{ value[1].newsTitle }}</span>
                            <p class="content">{{ value[1].newsDes }}</p>
                            <div class="all" @click="jump(value[1].newsId)">
                                {{ $t('Media.more') }}
                                <div class="next"></div>
                            </div>
                        </div>
                        <div class="next"></div>
                    </div>
                    <!---->
                    <div class="dynamic_content_show" v-if="value[2]">
                        <div class="cover">
                            <img :src="imgUrl(baseUrl,value[2].coverUrl)" alt="">
                        </div>
                        <div class="show-content">
                            <p class="time">{{ $getTime(value[2].createTime).year }}/{{
                                    $getTime(value[2].createTime).month
                                }}/{{ $getTime(value[2].createTime).day }}</p>
                            <span class="title">{{ value[2].newsTitle }}</span>
                            <p class="content">{{ value[2].newsDes }}</p>
                        </div>
                        <div class="significance">
                            <p class="time">{{ $getTime(value[2].createTime).year }}/{{
                                    $getTime(value[2].createTime).month
                                }}/{{ $getTime(value[2].createTime).day }}</p>
                            <span class="title">{{ value[2].newsTitle }}</span>
                            <p class="content">{{ value[2].newsDes }}</p>
                            <div class="all" @click="jump(value[2].newsId)">
                                {{ $t('Media.more') }}
                                <div class="next"></div>
                            </div>
                        </div>
                        <div class="next"></div>
                    </div>
                </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination show-pages"></div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <slot name="navbar"></slot>
        </div>
    </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import { imgUrl } from "@/utils/urlutils"
import Swiper from "swiper"
import { mapState } from 'vuex'

export default {
    name: "DynamicSwiper",
    components: {},
    props: ['news'],
    data () {
        return {}
    },
    created () {
        if (this.news.length) {
            this.createSwiper()
        }
    },
    mounted () {
        //let swiperCom = new Swiper('.swiper1', {
        //    observer: true,//自动初始化swiper
        //    observeParents: true//自动初始化swiper
        //})
        this.createSwiper()
    },
    methods: {
        imgUrl,
        //点击跳转
        jump (id) {
            this.$router.push({
                path: '/content',
                query: {
                    id: id
                }
            })
        },

        createSwiper () {
            let swiperCom = new Swiper('.game', {
                observer: true,//自动初始化swiper
                observeParents: true,//自动初始化swiper
                //自动开始
                autoplay: {
                    delay: 5000, //时间间隔
                    disableOnInteraction: false //*手动操作轮播图后不会暂停*
                },
                loop: true,// 循环模式选项
                //分页器
                pagination: {
                    el: '.show-pages',
                    clickable: true,
                    autoplayDisableOnInteraction: false,
                    paginationClickable: true
                },
                //前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            })
        }
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------DynamicSwiper---------- --*/
.dynamic_content {
    //margin-top: 6.875rem;
    height: 39.9375rem;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    &_show {
        width: 30%;
        height: 100%;
        background-color: #F0F0F0;
        position: relative;
        overflow: hidden;
margin-right: 5%;
        .cover {
            height: 21.4375rem;

            img {
                width: 100%;
                //height: 21.4375rem;
                height: 100%;
            }
        }

        .show-content {
            padding: 2.125rem 2.625rem 1.5rem 2.3125rem;

            .time {
                font-size: 1.25rem;
                font-weight: 600;
            }

            .title {
                font-size: 1.5rem;
                line-height: 3rem;
                font-family: 'AlibabaPuHuiTiM';
            }

            .content {
                font-size: 1.125rem;
                line-height: 1.875rem;
                font-family: 'AlibabaPuHuiTiL';
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
        }

        .next {
            width: 1.4375rem;
            height: 1.0625rem;
            background-image: url('../../../../assets/next_02.png');
            background-size: 100% auto;
            position: absolute;
            right: 2.625rem;
            bottom: 1.5rem;
        }

        .significance {
            width: 94%;
            height: 94%;
            background-color: #ED121B;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            padding: 9.375rem 3.75rem 3.75rem 3.75rem;
            box-sizing: border-box;
            opacity: 0;
            transition: all 1s cubic-bezier(0.390, 0.575, 0.565, 1.000);
            transform: translateY(200px);

            .time {
                font-size: 1.25rem;
                font-weight: 600;
            }

            .title {
                font-size: 1.5rem;
                line-height: 3rem;
                font-weight: 600;
                font-family: 'AlibabaPuHuiTiM';
            }

            .content {
                font-size: 1.125rem;
                line-height: 1.875rem;
                margin-top: 1.875rem;
                color: #fff;
                font-family: 'AlibabaPuHuiTiL';
            }

            .all {
                color: #ED121B;
                font-size: 1.125rem;
                width: 11.0625rem;
                height: 2.625rem;
                line-height: 2.625rem;
                background-color: #000;
                border-radius: 0.5rem;
                position: absolute;
                left: calc(50% - 5.0625rem);
                bottom: 3.75rem;
                padding: 0 0.75rem;
                box-sizing: border-box;
                font-family: 'AlibabaPuHuiTiM';
                cursor: pointer;

                .next {
                    width: 1.4375rem;
                    height: 1.0625rem;
                    background-image: url('../../../../assets/next_02.png');
                    background-size: 100% auto;
                    position: absolute;
                    right: 0.75rem;
                    bottom: 0.75rem;
                }
            }
        }

        &:hover {
            .significance {
                opacity: 1;
                transform: translateY(0);
            }
        }
        &:last-child{
            margin-right: 0;
        }
    }

}

.Swiper {
    height: 43.9375rem;
    margin-top: 6.875rem;
    //overflow: hidden;
    .swiper-container {
        //overflow: auto;
        height: 43.9375rem;
        /* 分页器  */

        .swiper-pagination {
            right: calc(50% - 3.125rem);
            left: auto;
            bottom: 1rem;
            //margin: 0 auto;
            width: auto;

            ::v-deep .swiper-pagination-bullet {
                width: 2.3125rem;
                height: 0.1875rem;
                border-radius: 0.5rem;
                background-color: #B0B1B5;
                opacity: 1;

            }

            .swiper-pagination-bullet-active {
                background-color: red !important;
            }
        }


    }

}

</style>
