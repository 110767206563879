<template>
    <div class="library" :class="{m_library:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[4].menuList[2]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="library_header">
            <div class="library_header_main">
                <div class="library_header_main_once"
                     @click="headClick(index)"
                     v-for="(value,index) in $t('Media.library')"
                     :key="index"
                     >
                    <div class="logo" :class="{active:indexs===index}"></div>
                    <span>{{ value.toUpperCase() }}</span>
                </div>
            </div>
        </div>
        <div class="library_show">
            <div class="bar" :class="{active:indexs===index}" v-if="!$store.state.screenType"
                 v-for="(value,index) in library" :key="index">
                <div class="library_show_item">
                    <div class="cover" v-for="(val,i) in value.list" :key="i" v-if="i!==5">
                        <div class="cover_show">
                            <img :src="imgUrl(baseUrl,val.photoUrl)" alt="">
                        </div>
                        <div class="cover_title">{{ val.descr }}</div>
                        <div class="cover_bar">
                            <!-- <img :src="imgUrl(baseUrl,val.photoUrl)" alt=""> -->
                            <div class="cover_bar_content">
                                <span>{{ val.descr }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="cover btn">
                        <div class="center">
                            <span>{{ $t('Media.libraryTitle') }}</span>
                            <div class="all" @click="jumpPage(index)">
                                {{ $t('Media.all') }}
                                <div class="next"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--移动端-->
            <div class="small-bar" :class="{small_bar:indexs===index}" v-if="$store.state.screenType"
                 v-for="(value,index) in library" :key="index">
                <div class="cover" v-for="(val,i) in value.list" :key="i"  v-if="i < 3">
                    <div class="cover_show">
                        <img :src="imgUrl(baseUrl,val.photoUrl)" alt="">
                    </div>
                    <div class="cover_title">{{ val.descr }}</div>
                    <!--<div class="cover_bar">-->
                    <!--    <img :src="imgUrl(baseUrl,value.list[val - 1].photoUrl)" alt="">-->
                    <!--    <div class="cover_bar_content">-->
                    <!--        <span>{{ value.list[val - 1].descr }}</span>-->
                    <!--    </div>-->
                    <!--</div>-->
                </div>
                <div class="cover btn">
                    <div class="center">
                        <span>{{ $t('Media.libraryTitle') }}</span>
                        <div class="all" @click="jumpPage(index)">
                            {{ $t('Media.all') }}
                            <div class="next"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "LibraryModule",
    components: {
        TitleH
    },
    props: ['library'],
    data () {
        return {
            indexs: 0,
            list: [
                '视频资料',
                '企业图片',
                '活动图集'
            ],
            index: [10, 11, 12]
        }
    },
    methods: {
        imgUrl,
        headClick (index) {
            this.indexs = index
        },
        jumpPage (value) {
            //console.log('s')
            this.$router.push({
                path: '/library',
                query: {
                    type: this.list[value],
                    index: this.index[value]
                }
            })
        }
    },
    computed: {
        ...mapState(['baseUrl'])
    },
    mounted () {
        console.log('this.$store.state.screenType is :', this.$store.state.screenType);
        console.log('props is :', this.library);
    }

}
</script>


<style scoped lang="scss">

/*-- -------LibraryModule---------- --*/
.library {
    padding: 6.5rem 0;
    height: auto;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;

    &_header {
        margin-top: 6.25rem;
        width: 100%;
        height: 4.375rem;
        background-color: #F7F6F4;
        display: flex;
        justify-content: center;

        &_main {
            width: 40%;
            display: flex;
            justify-content: space-between;
            border-top: 0.125rem solid #B0B1B5;
            box-sizing: border-box;


            &_once {
                width: 9.75rem;
                height: 10.875rem;
                background-color: #fff;
                border-top: 0.125rem solid #ED121B;
                box-sizing: border-box;
                margin-top: -0.125rem;
                text-align: center;
                line-height: 4.375rem;
                cursor: pointer;
                font-size: 1rem;

                .logo {
                    width: 3.625rem;
                    height: 2.8125rem;
                    padding: 2.4375rem 3.125rem 0 3.125rem;
                    background: url('../../../assets/media.png') no-repeat center center;
                    background-size: 3.625rem auto;
                    display: none;
                }

                .active {
                    display: block;

                }

                &:nth-child(2) {
                    .logo {
                        background: url('../../../assets/media01.png') no-repeat center center;
                        background-size: 3.025rem auto;
                    }
                }

                &:nth-child(3) {
                    .logo {
                        background: url('../../../assets/media02.png') no-repeat center center;
                        background-size: 3.025rem auto;
                    }
                }
            }
        }
    }

    &_show {
        padding: 0 7.5rem;
        height: 65.625rem;
        margin-top: 6.25rem;
        position: relative;
        //height: auto;
        .bar {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            width: 100%;
            height: 100%;
            padding: 0 7.5rem;
            box-sizing: border-box;
            //height: auto;
            .library_show_item {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .cover {
                    width: 31%;
                    height: 31.25rem;
                    background-color: #F0F0F0;
                    margin-bottom: 1.5625rem;
                    position: relative;
                    overflow: hidden;

                    &_show {
                        height: 75%;

                        img {
                            width: 100%;
                            height: 100%;
                            //height: 21.8125rem;
                        }
                    }

                    &_title {
                        height: 25%;
                        padding: 2.875rem 2.625rem;
                        font-size: 1.5rem;
                        box-sizing: border-box;
                    }

                    &_bar {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        text-align: center;
                        overflow: hidden;
                        opacity: 1;
                        transform: translateY(0);
                        transition: all .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                        img {
                            position: relative;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            height: 100%;
                        }

                        &_content {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: rgba(0, 0, 0, 0.67);
                            text-align: center;
                            font-size: 1.875rem;
                            color: #fff;

                            span {
                                width: 75%;
                                height: 3.75rem;
                                display: block;
                                position: absolute;
                                bottom: 0;
                                top: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                            }
                        }
                    }

                    &:hover {
                        .cover_bar {
                            opacity: 0;
                            transform: translateY(100px);
                        }
                    }
                }

                .btn {
                    background-color: #ED111C;
                    position: relative;
                    cursor: pointer;

                    .center {
                        width: 40%;
                        height: 25%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        text-align: center;

                        span {
                            font-size: 2.25rem;
                            font-weight: 800;

                        }

                        .all {
                            width: 6.25rem;
                            height: 3.125rem;
                            border-radius: 0.5rem;
                            background-color: #000;
                            color: #ED111C;
                            line-height: 3.125rem;
                            position: relative;
                            padding: 0 1rem;
                            text-align: left;
                            font-size: 1rem;
                            margin: 0.75rem auto;

                            .next {
                                width: 1.375rem;
                                height: 0.875rem;
                                background-image: url('../../../assets/next_02.png');
                                background-size: 100% auto;
                                position: absolute;
                                right: 1rem;
                                bottom: 1.125rem;
                            }
                        }
                    }
                }
            }
        }

        .active {
            display: block;
        }

        .small-bar {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            width: 100%;
            height: auto;
            min-height: 16rem;
            padding: 0 3.125rem;
            box-sizing: border-box;


            .cover {
                width: 46%;
                background-color: #F0F0F0;
                margin-bottom: 1.5625rem;
                position: relative;

                &_show {
                    height: 80%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                &_title {
                    height: 20%;
                    padding: 1rem;
                    font-size: 1rem;
                    box-sizing: border-box;
                    overflow:hidden; //超出的文本隐藏
                    text-overflow:ellipsis; //溢出用省略号显示
                    white-space:nowrap; //溢出不换行
                }

                //&_bar {
                //    width: 100%;
                //    height: 100%;
                //    position: absolute;
                //    top: 0;
                //    left: 0;
                //    text-align: center;
                //    overflow: hidden;
                //    opacity: 1;
                //    transform: scale(1);
                //    transition: all .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
                //    img {
                //        position: relative;
                //        left: 50%;
                //        top: 50%;
                //        transform: translate(-50%, -50%);
                //        height: 100%;
                //    }
                //
                //    &_content {
                //        width: 100%;
                //        height: 100%;
                //        position: absolute;
                //        top: 0;
                //        left: 0;
                //        background-color: rgba(0, 0, 0, 0.67);
                //        text-align: center;
                //        font-size: 1.875rem;
                //        color: #fff;
                //
                //        span {
                //            width: 75%;
                //            height: 3.75rem;
                //            display: block;
                //            position: absolute;
                //            bottom: 0;
                //            top: 0;
                //            left: 0;
                //            right: 0;
                //            margin: auto;
                //        }
                //    }
                //}
                //&:hover{
                //    .cover_bar{
                //        opacity: 0;
                //        transform: scale(0);
                //    }
                //}
            }

            .btn {
                background-color: #ED111C;
                position: relative;

                .center {
                    width: 50%;
                    height: 30%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    text-align: center;

                    span {
                        font-size: 1.125rem;
                        font-weight: 800;

                    }

                    .all {
                        width: 8.25rem;
                        height: 3.125rem;
                        border-radius: 0.5rem;
                        background-color: #000;
                        color: #ED111C;
                        line-height: 3.125rem;
                        position: relative;
                        padding: 0 1rem;
                        text-align: left;
                        font-size: 1rem;
                        margin: 0.75rem auto;

                        .next {
                            width: 1.375rem;
                            height: 0.875rem;
                            background-image: url('../../../assets/next_02.png');
                            background-size: 100% auto;
                            position: absolute;
                            right: 1rem;
                            bottom: 1.125rem;
                        }
                    }
                }
            }
        }
    }

}

@import "css/m-library";
</style>
