<template>
    <div class="Media" :class="{m_media:$store.state.screenType}">
        <Navbar @barClick="index"></Navbar>
        <SwiperCom :list="banners" class="swiper" v-if="banners.length"></SwiperCom>
        <ScrollBar :menulist="$t('Nav[4]')" @barClick="index"></ScrollBar>
        <!--企业动态-->
        <DynamicModule class="scrollBar" :company="companyNewsList"></DynamicModule>
        <div class="line-line"></div>
        <!--赛事动态-->
        <GameModule class="scrollBar" :game="raceNewsList"></GameModule>
        <div class="line-line"></div>
        <!--媒体资料库-->
        <LibraryModule class="scrollBar" :library="sourceList"></LibraryModule>
        <div class="line-line"></div>
        <!--办公地点-->
        <LocationModules class="scrollBar" :countrys="countrys" :contact="contactList"></LocationModules>
        <router-view/>
        <Footers></Footers>
        <top></top>
    </div>
</template>

<script>

import Navbar from '../../components/Nav/Navbar'
import SwiperCom from '../../components/SwiperCom'
import Footers from '../../components/Footer/Footers'
import ScrollBar from '../../components/ScrollBar'
import DynamicModule from './MediaMoudle/DynamicModule'
import GameModule from './MediaMoudle/GameModule'
import LocationModules from './MediaMoudle/LocationModules'
import LibraryModule from './MediaMoudle/LibraryModule'
import { result } from '../../tools/ScrollBar'

export default {
    name: "Media",
    mixins: [result],
    components: {
        Navbar,
        SwiperCom,
        ScrollBar,
        Footers,
        GameModule,
        DynamicModule,
        LocationModules,
        LibraryModule
    },
    data () {
        return {
            imgList: [
                require('../../assets/banner/banner_05.png'),
                require('../../assets/banner/banner_05.png')
            ],
            //head头部内容
            meta: {},
            banners: [],//轮播图数据
            companyNewsList: [],//公司动态
            raceNewsList: [],//赛事动态
            countrys: [],//公司地址
            contactList: [],//联系我们
            sourceList: []//媒体资料库
        }
    },
    methods: {
        index (value) {
            this.scrollClick(value)
        },
        //请求head数据
        getList () {
            this.$http.getPage(this.$store.state.languageIndex, 6).then(res => {
                //console.log(res)
                this.meta = res.data.data
                this.banners = res.data.data.banners
                this.$getMedia()
            })
        },
        getAllList () {
            this.$http.getMedia(this.$store.state.languageIndex).then(res => {
                //console.log(res.data.data)
                this.countrys = res.data.data.countrys
                this.contactList = res.data.data.contactList
                this.sourceList = res.data.data.sourceList
                this.raceNewsList = this.arrayChange(res.data.data.raceNewsList,3)
                this.companyNewsList = this.arrayChange(res.data.data.companyNewsList,3)
                //console.log(this.companyNewsList)
            })
        },
        //用来将数组切割成具体的数据
        arrayChange (data, num) {
            let index = 0
            let array = []
            while (index < data.length) {
                array.push(data.slice(index, index += num));
            }
            return array;
        }
    },
    created () {
        this.getList()
        this.getAllList()
    },
    watch: {
        //监听vuex中的语言状态，当发生改变的时候，更新页面中的数据
        '$store.state.languageIndex' () {
            this.getList()
            this.getAllList()
        }
    }

}
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------Media---------- --*/
.Media {
    font-size: 0;

    .swiper {
        padding-top: 6.25rem;
    }

    ::v-deep [class^='swiper-button'] {
        display: none;
    }

    .line-line {
        width: 80%;
        height: 0.0625rem;
        background-color: #C7C4C7;
        margin: 0 auto;
    }
}

@import "m-media";
</style>
